<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :needBtnLoading="true"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    > 
      <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addOneMenu">添加一级菜单</el-button>
      </div>
    </GlobalForm>
    <!--弹框-->
    <el-dialog
      title="添加一级菜单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <el-form ref="menuForm" :model="form" :rules="menuRules" label-width="92px">
        <el-form-item label="菜单名称：" prop="menuName">
          <el-input v-model="form.menuName" maxlength="8" placeholder="请输入菜单名称，最多8个字"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径：" prop="menuPath">
          <el-input v-model="form.menuPath" maxlength="50" placeholder="请输入菜单路径" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标：" prop="menuIcon">
          <div style="color:#FF687B;font-size:14px">请上传16*16px的图片</div>
          <ElImgUpload
            :exist-image="form.menuIcon"
            @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)"
          />
        </el-form-item>
        <el-form-item label="动态口令：" prop="command">
          <el-input v-model="form.command" placeholder="请输入动态口令"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
import ElImgUpload from '../../../components/global/components/elements/upload-element'
import _api from "@/utils/request";
export default {
  name: 'Form',
  components: {
		ElImgUpload
	},
  data() {
    return {
      dialogVisible: false,
      initData: null,
      formItemList: [
        {
          key: 'menuName',
          type: 'input',
          labelName: '菜单名称',
          placeholder: '请输入菜单名称'
        },
        {slot:"AddSlot"}
      ],
      formRules: {
      },
      form: {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: "",
      },
      menuRules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: ['blur'] },
        ],
        menuPath: [
          { required: true, message: '请输入菜单路径', trigger: ['blur'] },
        ],
        menuIcon: [
          { required: true, message: '请上传菜单图标', trigger: ['change'] },
        ],
        command: [
          { required: true, message: '请输入动态口令', trigger: ['blur'] },
        ],
      }
    }
  },
  created() {
  },
  methods: {
    addOneMenu() {
      this.dialogVisible = true;
    },
    handleDialogClosed() {
      this.form = {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: "",
      },
      this.$refs["menuForm"].resetFields();
      this.dialogVisible = false
    },
    //删除图片
    handleUploadDelete () {
			this.form.menuIcon = ''
		},
		// 上传图片后，将图片信息保存到对应参数中
		handleUploadChange (url) {
			this.form.menuIcon = url
		},
    //查询
    handleConfirm(data,cd) {
      const SeachParams = {
        menuName:data.menuName,
        pageNum:1,
        pageSize:10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    //弹窗确定
    handleSuccess() {
      this.$refs['menuForm'].validate((valid) => {
        if (valid) {
          let params = {
            menuIcon: this.form.menuIcon,
            menuName: this.form.menuName,
            menuUrl	: this.form.menuPath,
            command: this.form.command
          }
          _api.platformSaveMenu(params).then(res => {
            if(res.code === 1){
              this.$message.success("操作成功");
              this.dialogVisible = false;
              this.handleCurrentChange()
            }else{
              this.$message.error(res.msg);
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
