<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="排序（数字越大，排序越靠前）" slot="sortOperation" align="center">
        <template slot-scope="{ row }">
          <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row,'one')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="状态" slot="isEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row,'one')"
            v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'edit')">编辑</el-button>
          <el-button size="mini" type="warning" round @click="handleBtn(row, 'subordinate')">查看下级</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <el-form ref="menuForm" :model="form" :rules="menuRules" label-width="92px">
        <el-form-item label="菜单名称：" prop="menuName">
          <el-input v-model="form.menuName" maxlength="8" placeholder="请输入菜单名称，最多8个字"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径：" prop="menuPath">
          <el-input v-model="form.menuPath" maxlength="50" placeholder="请输入菜单路径" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标：" v-if="addType=='one'" prop="menuIcon">
          <div style="color:#FF687B;font-size:14px">请上传16*16px的图片</div>
          <ElImgUpload
            :exist-image="form.menuIcon"
            @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)"
          />
        </el-form-item>
        <el-form-item label="动态口令：" prop="command">
          <el-input v-model="form.command" placeholder="请输入动态口令"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看二级菜单"
      :visible.sync="nextDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="nextClosed"
    >
      <div style="margin-bottom: 20px">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addTwoMenu">添加二级菜单</el-button>
      </div>
      <GlobalTable
        ref="twoTable"
        v-loading="twoLoading"
        :columns="twoTableColumns"
        :data="twoDataList"
        :currentPage="twoPage.pageNum"
        :total="twoPage.total"
        @handleCurrentChange="twoHandleCurrentChange"
      >
        <el-table-column label="排序（数字越大，排序越靠前）" slot="sortOperation" align="center">
          <template slot-scope="{ row }">
            <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row,'two')"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="状态" slot="isEnable" align="center">
          <template slot-scope="{ row }">
            <el-switch
              inactive-color="#bebebe"
              @change="handleSwitch(row,'two')"
              v-model="row.isEnable"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="100" label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="handleBtn(row, 'twoEdit')">编辑</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <el-dialog
      title='提示'
      :visible.sync="enableShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="enableClosed"
    >
      <div class="enable-tip">
        <div class="tip-sure">是否确认{{enableType=='01'?'禁用':'启用'}}该菜单？</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableCancel">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="enableSubmit">确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MinXinRequest from "@/mixins/request";
import ElImgUpload from '../../../../components/global/components/elements/upload-element'
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  components: {
		ElImgUpload
	},
  data() {
    return {
      loading: false,
      twoLoading: false,
      seachDataList: [],
      twoDataList: [],
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      nextDialogVisible: false, // 二级菜单弹框
      enableShow: false, //启用禁用弹框
      butLoading: false,
      command: "",
      enableType: "",
      clickType: "",
      oneMenuId: "", //一级菜单ID
      oneIsEnable: "",
      dialogTitle: "", // 标题
      dialongDecide: "",
      resetId: "", //密码重置ID
      tableColumns: [
        { label: "一级菜单名称", prop: "menuName" },
        { slotName: "sortOperation" },
        { slotName: "isEnable" },
        { slotName: "operation" }
      ],
      twoTableColumns: [
        { label: "二级菜单名称", prop: "menuName" },
        { slotName: "sortOperation" },
        { slotName: "isEnable" },
        { slotName: "operation" }
      ],
      form: {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: "",
      },
      menuRules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: ['blur'] },
        ],
        menuPath: [
          { required: true, message: '请输入菜单路径', trigger: ['blur'] },
        ],
        menuIcon: [
          { required: true, message: '请上传菜单图标', trigger: ['change'] },
        ],
        command: [
          { required: true, message: '请输入动态口令', trigger: ['blur'] },
        ],
      },
      addType: "",
      parentId: "",
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      twoPage: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1
      this.handleCurrentChange()
    }
  },
  methods: {
    // 一级菜单表格切换页面
    handleCurrentChange(val) {
      if(val){
        this.page.pageNum = val
      }
      const baseRequest = {
        menuName: this.SeachParams.menuName,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.platformMenuList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
        }
        this.loading = false
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 二级菜单表格切换页面
    twoHandleCurrentChange(val) {
      if(val){
        this.twoPage.pageNum = val
      }
      const baseRequest = {
        parentId: this.parentId,
        pageNum: this.twoPage.pageNum,
        pageSize: this.twoPage.pageSize,
      };
      this.twoLoading = true
      _api.platformMenuList(baseRequest).then(res => {
        if (res.code === 1) {
          this.twoDataList = res.data.records;
          this.twoPage.total = res.data.total;
          this.twoPage.pageNum = res.data.current;
        }
        this.twoLoading = false
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.oneMenuId = row.id
      if (type === "subordinate") {
        this.parentId = row.id
        this.twoPage.pageNum = 1
        this.twoHandleCurrentChange()
        this.nextDialogVisible = true;
      }else if(type === "edit"){
        this.dialogTitle = '编辑菜单'
        this.form = {
          menuName: row.menuName,
          menuPath: row.menuUrl,
          menuIcon: row.menuIcon,
          command: "",
        }
        this.addType = "one"
        this.dialogVisible = true;
      }else if(type === 'twoEdit'){
        this.dialogTitle = '编辑二级菜单'
        this.form = {
          menuName: row.menuName,
          menuPath: row.menuUrl,
          command: "",
        }
        this.addType = "two"
        this.dialogVisible = true;
      }
    },
    //弹窗取消
    handleDialogClosed() {
      this.form = {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: "",
      }
      this.$refs["menuForm"].resetFields();
      this.dialogVisible = false;
    },
    // 二级菜单弹框关闭
    nextClosed() {
      this.nextDialogVisible = false;
    },
    // 添加二级菜单
    addTwoMenu() {
      this.dialogTitle = '添加二级菜单'
      this.addType = "two"
      this.oneMenuId = ""
      this.dialogVisible = true;
    },
    //弹窗确定
    handleSuccess() {
      this.$refs['menuForm'].validate((valid) => {
        if (valid) {
          let params = {
            id: this.oneMenuId,
            menuIcon: this.form.menuIcon,
            menuName: this.form.menuName,
            menuUrl	: this.form.menuPath,
            command: this.form.command,
            parentId: this.addType == "two"?this.parentId: -1
          }
          _api.platformSaveMenu(params).then(res => {
            if(res.code === 1){
              this.$message.success("操作成功");
              this.dialogVisible = false;
              if(this.addType == "two"){
                this.twoHandleCurrentChange()
              }else{
                this.handleCurrentChange()
              }
            }else{
              this.$message.error(res.msg);
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除图片
    handleUploadDelete (key) {
			this.form.menuIcon = ''
		},
		// 上传图片后，将图片信息保存到对应参数中
		handleUploadChange (url, key) {
			this.form.menuIcon = url
		},
    // 启用禁用弹框显示
    handleSwitch(row,type) {
      this.clickType = type
      if(row.isEnable){
        this.enableType = '02'
      }else{
        this.enableType = '01'
      }
      this.oneIsEnable = row.isEnable
      this.oneMenuId = row.id
      this.enableShow = true
    },
    // 启用禁用弹框关闭
    enableCancel() {
      this.enableShow = false
    },
    enableClosed() {
      this.command = ""
      this.enableShow = false
      this.twoHandleCurrentChange()
      this.handleCurrentChange()
      // if(this.clickType == 'one'){
      //   this.handleCurrentChange()
      // }else if(this.clickType == 'two'){
      //   this.twoHandleCurrentChange()
      // }
    },
    // 启用禁用弹框确定
    enableSubmit() {
      if(!this.command){
        this.$message.error('请输入动态口令');
        return
      }
      let params = {
        command: this.command,
        id: this.oneMenuId,
        isEnable: this.oneIsEnable
      }
      this.butLoading = true
      _api.platformMenuEnable(params).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功");
          this.enableShow = false
        }else{
          this.$message.error(res.msg);
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    //排序
    handleChangeNum(val,type) {
      const params = {
        id: val.id,
        sort: val.sort
      };
      _api.platformSortMenu(params).then(res => {
        if (res.code === 1) {
          if(type == 'one'){
            this.handleCurrentChange();
          }else if(type == 'two'){
            this.twoHandleCurrentChange()
          }
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .enable-tip{
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure{
      margin-top: 10px;
      color:#333333;
      font-size:16px;
      text-align: center;
    }
  }
  .command{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
