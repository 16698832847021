<template>
  <div class="menu-manage">
    <GlobalInfoBar
        title="菜单管理"
        content="主要管理主后台和门店后台菜单的新增及编辑"
    />
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
            <div v-bind:class="clickShow=== '01'? 'left' : 'right'" @click="BtnClick('01')">
              主后台菜单
            </div>
            <div v-bind:class="clickShow=== '02'? 'left' : 'right'" @click="BtnClick('02')">
              门店后台菜单
            </div>
            <div v-bind:class="clickShow=== '03'? 'left' : 'right'" @click="BtnClick('03')">
              回收商后台菜单
            </div>
            <div v-bind:class="clickShow=== '04'? 'left' : 'right'" @click="BtnClick('04')">
              运营后台菜单
            </div>
        </div>
      </div>
    </div>
    <GlobalChunk
        v-if="clickShow=='01'"
        icon="search"
        title="筛选查询"
    >
      <div slot="filter">
        <From />
      </div>
      <MainTable />
    </GlobalChunk>
    <GlobalChunk
        v-if="clickShow=='02'"
    >
        <StoreTable />
    </GlobalChunk>
    <GlobalChunk
        v-if="clickShow=='03'"
    >
        <merchantTable />
    </GlobalChunk>
    <GlobalChunk
        v-if="clickShow=='04'"
    >
        <operationTable />
    </GlobalChunk>
  </div>
</template>

<script>
import From from './From'
import MainTable from './component/mainTable.vue'
import StoreTable from './component/storeTable.vue'
import merchantTable from "./component/merchantTable.vue"
import operationTable from "./component/operationTable.vue"
export default {
  name: 'menu-manage',
  components: {From , MainTable , StoreTable,merchantTable,operationTable},
  data(){
    return{
        clickShow:'01',
    }
  },
  created(){
    if(this.$route.query.companyId){
      console.log(this.$route.query.companyId);
      this.clickShow= '02'
    }
  },
  methods:{
      BtnClick(type){
        if(type ==  this.clickShow ){
            return false
        }
        this.clickShow=type
      }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.menu-manage{
.button_top{
    cursor: pointer;
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }
  .faultBtn{
    width: 520px;
    height: 42px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      background: #0981FF;
      border-radius: 20px;
    }
    .right{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}
</style>
