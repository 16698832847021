<template>
  <div class="GlobalTable">
    <div style="margin-bottom: 20px;display: flex; justify-content: space-between;">
      <el-radio-group v-model="radio" size="medium">
          <el-radio-button label="01" >菜单管理</el-radio-button>
          <el-radio-button label="02">菜单配置</el-radio-button>
      </el-radio-group>
      <el-button size="small" type="primary" v-if="radio == '01'" icon="el-icon-plus" @click="handleBtn('addOneMenu')">添加一级菜单</el-button>
    </div>
    <div class="el-form-group_isRound" style="margin-bottom: 20px;display: flex;align-items: center;" v-if="radio=='02'">
        <div style="font-size: 14px;color: #666666;">门店商家：</div>
        <el-select
          size="small"
          v-model="companyId"
          placeholder="请输入或选择门店商家"
          filterable
          clearable
        >
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div style="font-size: 14px;color: #666666;margin-left: 20px">是否启用门店后台：</div>
        <el-select
          size="small"
          v-model="isOpenPc"
        >
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div style="margin-left:20px;">
          <el-button type="primary" round size="small" @click="handleConfirm">查询</el-button>
        </div>
    </div>
    <GlobalTable
      v-if="radio == '01'"
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="排序（数字越大，排序越靠前）" slot="sortOperation" align="center">
        <template slot-scope="{ row }">
          <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row,'one')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="状态" slot="isEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="isEnableSwitch(row,'one')"
            v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="统一配置到所有门店商家" slot="allocation" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="isEnableSwitch(row,'oneUnify')"
            v-model="row.isConfigToAll"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn('edit',row)">编辑</el-button>
          <el-button size="mini" type="warning" round @click="handleBtn('subordinate',row)">查看下级</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <GlobalTable
      v-if="radio == '02'"
      ref="GlobalTable"
      v-loading="configloading"
      :columns="configColumns"
      :data="this.configDataList"
      :currentPage="configPage.pageNum"
      :total="this.configPage.total"
      @handleCurrentChange="configHandleCurrentChange"
    >
      <el-table-column label="是否开通门店后台" slot="isOpenPc" align="center">
        <template slot-scope="{ row }">
          <span>{{row.isOpenPc?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="已启用/全部二级菜单" slot="enableMenus" align="center">
        <template slot-scope="{ row }">
          <span>{{row.enableMenus}}/{{row.allMenus}}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="editStoreMenu(row)">编辑门店菜单</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <el-form ref="storeMenuform" :model="form" :rules="menuRules" label-width="92px">
        <el-form-item label="菜单名称：" prop="menuName">
          <el-input v-model="form.menuName" maxlength="8" placeholder="请输入菜单名称，最多8个字"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径：" prop="menuPath">
          <el-input v-model="form.menuPath" placeholder="请输入菜单路径" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标：" v-if="addType=='one'" prop="menuIcon">
          <div style="color:#FF687B;font-size:14px">请上传16*16px的图片</div>
          <ElImgUpload
            :exist-image="form.menuIcon"
            @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)"
          />
        </el-form-item>
        <el-form-item label="动态口令：" prop="command">
          <el-input v-model="form.command" placeholder="请输入动态口令"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看二级菜单"
      :visible.sync="nextDialogVisible"
      :close-on-click-modal="false"
      width="850px"
      @closed="nextClosed"
    >
      <div style="margin-bottom: 20px">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addTwoMenu">添加二级菜单</el-button>
      </div>
      <GlobalTable
        ref="twoTable"
        v-loading="twoLoading"
        :columns="twoTableColumns"
        :data="twoDataList"
        :currentPage="twoPage.pageNum"
        :total="twoPage.total"
        @handleCurrentChange="twoHandleCurrentChange"
      >
        <el-table-column label="排序（数字越大，排序越靠前）" slot="sortOperation" align="center">
          <template slot-scope="{ row }">
            <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row,'two')"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="状态" slot="isEnable" width="70px" align="center">
          <template slot-scope="{ row }">
            <el-switch
              inactive-color="#bebebe"
              @change="isEnableSwitch(row,'two')"
              v-model="row.isEnable"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="统一配置到所有门店商家" slot="allocation" align="center">
          <template slot-scope="{ row }">
            <el-switch
              inactive-color="#bebebe"
              @change="isEnableSwitch(row,'twoUnify')"
              v-model="row.isConfigToAll"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="100" label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="handleBtn('twoEdit',row)">编辑</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <el-dialog
      title='提示'
      :visible.sync="enableShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="enableClosed"
    >
      <div class="enable-tip" v-if="clickType != 'oneUnify' && clickType != 'twoUnify'">
        <div class="tip-sure">是否确认{{enableType=='01'?'禁用':'启用'}}该菜单？</div>
      </div>
      <div class="enable-tip" v-else>
        <div v-if="enableType=='01'">说明：关闭后，该菜单将不会配置到任何商家门店后台菜单，请在菜单配置单独为门店添加。</div>
        <div class="tip-sure">{{enableType=='01'?'是否确认不再将该菜单的统一配置到门店商家？':'是否确认将该菜单统一配置给所有门店商家？'}}</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableCancel">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="enableSubmit">确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title='编辑门店后台菜单'
      :visible.sync="storeMenuTreeShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="storeMenuTreeClosed"
    >
      <div style="margin-bottom: 20px">
        <span>商家名称：<span style="color: #333333">{{companyName}}</span></span>
      </div>
      <div style="margin-bottom: 10px">
        <span>编辑菜单</span>
      </div>
      <div class="menuTreeBox">
        <el-tree
          v-loading="treeLoading"
          ref="tree"
          default-expand-all
          :data="TreeData"
          :props="props"
          :node-key="props.value"
          @check-change="getCheckedNodes"
          :default-checked-keys="treeListIds"
          show-checkbox
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="storeMenuTreeClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="storeMenuTreeSubmit">确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MinXinRequest from "@/mixins/request";
import ElImgUpload from '../../../../components/global/components/elements/upload-element'
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  components: {
		ElImgUpload
	},
  data() {
    return {
      radio: "01",
      loading: false,
      treeLoading: false,
      configloading: false,
      twoLoading: false,
      butLoading: false,
      seachDataList: [],
      configDataList: [],
      twoDataList: [],
      companyList: [],
      treeListIds:[],
      TreeData: [],
      treeIds:[],
      companyId: "",
      isOpenPc: "00",
      clickType: "",
      enableType: "",
      isEnable: "",
      menuId: "",
      command: "",
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      props: {
        value: 'id',
        children: 'children',
        label: 'menuName'
      },
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      nextDialogVisible: false, // 二级菜单弹框
      enableShow: false, //启用禁用弹框
      storeMenuTreeShow: false,
      companyName: "",
      dialogTitle: "", // 标题
      dialongDecide: "",
      resetId: "", //密码重置ID
      tableColumns: [
        { label: "一级菜单名称", prop: "menuName" },
        { slotName: "sortOperation" },
        { slotName: "isEnable" },
        { slotName: "allocation" },
        { slotName: "operation" }
      ],
      twoTableColumns: [
        { label: "二级菜单名称", prop: "menuName" },
        { slotName: "sortOperation" },
        { slotName: "isEnable" },
        { slotName: "allocation" },
        { slotName: "operation" }
      ],
      configColumns: [
        { label: "门店商家名称", prop: "companyName" },
        { slotName: "isOpenPc" },
        { slotName: "enableMenus" },
        { slotName: "operation" }
      ],
      option: [
        {
          value: '00',
          label: '全部'
        },
        {
          value: '01',
          label: '是'
        },
        {
          value: '02',
          label: '否'
        },
      ],
      form: {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: ""
      },
      parentId: "",
      addType: "",
      twoPage: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      configPage: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      menuRules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: ['blur'] },
        ],
        menuPath: [
          { required: true, message: '请输入菜单路径', trigger: ['blur'] },
        ],
        menuIcon: [
          { required: true, message: '请上传菜单图标', trigger: ['change'] },
        ],
        command: [
          { required: true, message: '请输入动态口令', trigger: ['blur'] },
        ],
      },
    };
  },
  computed: {
  },
  created() {
    _api.getSelectList({isAssignAccount: true}).then((res) => {
      if (res.code === 1) {
        this.companyList = res.data
      }
    })
    if(this.$route.query.companyId){
      this.radio='02'
      this.companyId=Number(this.$route.query.companyId)
      this.configHandleCurrentChange();
    }else{
      this.radio='01'
      this.handleCurrentChange();
    }
   
  },
  watch:{
    radio(val){
      if(val == '01'){
        this.handleCurrentChange();
      }else{
        this.configHandleCurrentChange();
      }
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if(val){
        this.page.pageNum = val
      }
      const baseRequest = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.companyMenuList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
        }
        this.loading = false
      });
    },
    // 菜单配置表格切换页面
    configHandleCurrentChange(val) {
      if(val){
        this.configPage.pageNum = val
      }
      const baseRequest = {
        isOpenPc: this.isOpenPc,
        companyId: this.companyId,
        pageNum: this.configPage.pageNum,
        pageSize: this.configPage.pageSize,
      };
      this.configloading = true
      _api.companyConfigList(baseRequest).then(res => {
        if (res.code === 1) {
          this.configDataList = res.data.records;
          this.configPage.total = res.data.total;
          this.configPage.pageNum = res.data.current;
        }
        this.configloading = false
      });
    },
    // 二级菜单表格切换页面
    twoHandleCurrentChange(val) {
      if(val){
        this.twoPage.pageNum = val
      }
      const baseRequest = {
        parentId: this.parentId,
        pageNum: this.twoPage.pageNum,
        pageSize: this.twoPage.pageSize,
      };
      this.twoLoading = true
      _api.companyMenuList(baseRequest).then(res => {
        if (res.code === 1) {
          this.twoDataList = res.data.records;
          this.twoPage.total = res.data.total;
          this.twoPage.pageNum = res.data.current;
        }
        this.twoLoading = false
      });
    },
    companyFindMenuTreeMenu() {
      this.treeLoading = true
      _api.companyFindMenuTreeMenu({companyId: this.storeMenuCompanyId}).then(res => {
        if(res.code === 1){
          this.TreeData = res.data
          res.data.forEach(item =>{
            if(item.isSelected){
              this.treeIds.push(item.id)
            }
            if(item.menuName == '首页' && item.isSelected){
              this.treeListIds.push(item.id)
            }
            if(item.children && item.children.length> 0){
              item.children.forEach(ele => {
                if(ele.isSelected){
                  this.treeIds.push(ele.id)
                  this.treeListIds.push(ele.id)
                }
              })
            }
          })
          console.log(this.treeIds);
        }
        this.treeLoading = false
      })
    },
    // 表格按钮操作
    handleBtn(type,row) {
      if (type === "subordinate") {
        this.parentId = row.id
        this.twoPage.pageNum = 1
        this.twoHandleCurrentChange()
        this.nextDialogVisible = true;
      }else if(type === "edit"){
        this.dialogTitle = '编辑菜单'
        this.menuId = row.id
        this.form = {
          menuName: row.menuName,
          menuPath: row.menuUrl,
          menuIcon: row.menuIcon,
          command: "",
        }
        this.addType = "one"
        this.dialogVisible = true;
      }else if(type === 'twoEdit'){
        this.dialogTitle = '编辑二级菜单'
        this.menuId = row.id
        this.form = {
          menuName: row.menuName,
          menuPath: row.menuUrl,
          command: "",
        }
        this.addType = "two"
        this.dialogVisible = true;
      }else if(type === 'addOneMenu'){
        this.menuId = ""
        this.dialogTitle = '添加一级菜单'
        this.addType = "one"
        this.dialogVisible = true;
      }
    },
    //弹窗取消
    handleDialogClosed() {
      this.form = {
        menuName: "",
        menuPath: "",
        menuIcon: "",
        command: ""
      }
      this.dialogVisible = false;
      this.$refs["storeMenuform"].resetFields();
    },
    // 二级菜单弹框关闭
    nextClosed() {
      this.nextDialogVisible = false;
    },
    // 添加二级菜单
    addTwoMenu() {
      this.dialogTitle = '添加二级菜单'
      this.addType = "two"
      this.menuId = ""
      this.dialogVisible = true;
    },
    //弹窗确定
    handleSuccess() {
      this.$refs['storeMenuform'].validate((valid) => {
        if (valid) {
          let params = {
            id: this.menuId,
            menuIcon: this.form.menuIcon,
            menuName: this.form.menuName,
            menuUrl	: this.form.menuPath,
            command: this.form.command,
            parentId: this.addType == "two"?this.parentId : -1
          }
          _api.companySaveMenu(params).then(res => {
            if(res.code === 1){
              this.$message.success("操作成功");
              this.dialogVisible = false;
              if(this.addType == "two"){
                this.twoHandleCurrentChange()
              }else{
                this.handleCurrentChange()
              }
            }else{
              this.$message.error(res.msg);
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除图片
    handleUploadDelete (key) {
			this.form.menuIcon = ''
		},
		// 上传图片后，将图片信息保存到对应参数中
		handleUploadChange (url, key) {
			this.form.menuIcon = url
		},
    // 商家查询数据
    handleConfirm() {
      this.configPage.pageNum = 1
      this.configHandleCurrentChange()
    },
    // 启用禁用弹框关闭
    enableCancel() {
      this.enableShow = false
    },
    enableClosed() {
      this.command = ""
      this.enableShow = false
      this.handleCurrentChange()
      this.twoHandleCurrentChange()
      // if(this.clickType == 'one' || this.clickType == 'oneUnify'){
      //   this.handleCurrentChange()
      // }else if(this.clickType == 'two' || this.clickType == 'twoUnify'){
      //   this.twoHandleCurrentChange()
      // }
    },
    // 启用禁用/统一配置到所有门店商家弹框显示
    isEnableSwitch(row,type) {
      this.clickType = type
      if(type != 'oneUnify' && type != 'twoUnify' ){
        if(row.isEnable){
          this.enableType = '02'
        }else{
          this.enableType = '01'
        }
        this.isEnable = row.isEnable
      }else{
        if(row.isConfigToAll){
          this.enableType = '02'
        }else{
          this.enableType = '01'
        }
        this.isEnable = row.isConfigToAll
      }

      this.menuId = row.id
      this.enableShow = true
    },
    // 启用禁用弹框确定
    enableSubmit() {
      if(!this.command){
        this.$message.error('请输入动态口令');
        return
      }
      let params = {}
      if(this.clickType != 'oneUnify' && this.clickType != 'twoUnify'){
        params = {
          command: this.command,
          id: this.menuId,
          isEnable: this.isEnable
        }
        this.butLoading = true
        _api.companyMenuEnable(params).then(res => {
          if(res.code === 1){
            this.$message.success("操作成功");
            this.enableShow = false
          }else{
            this.$message.error(res.msg);
          }
          this.butLoading = false
        }).catch(err => {
          this.butLoading = false
        })
      }else{
        params = {
          command: this.command,
          id: this.menuId,
          isConfig: this.isEnable
        }
        this.butLoading = true
        _api.companyConfigToAll(params).then(res => {
          if(res.code === 1){
            this.$message.success("操作成功");
            this.enableShow = false
          }else{
            this.$message.error(res.msg);
          }
          this.butLoading = false
        }).catch(err => {
          this.butLoading = false
        })
      }

    },
    //排序
    handleChangeNum(val,type) {
      const params = {
        id: val.id,
        sort: val.sort
      };
      _api.companySortMenu(params).then(res => {
        if (res.code === 1) {
          if(type == 'one'){
            this.handleCurrentChange();
          }else if(type == 'two'){
            this.twoHandleCurrentChange()
          }
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 编辑门店菜单
    editStoreMenu(row) {
      this.companyName = row.companyName
      this.storeMenuTreeShow = true
      this.storeMenuCompanyId = row.id
      this.companyFindMenuTreeMenu()
    },
    // 门店菜单关闭
    storeMenuTreeClosed() {
      this.storeMenuTreeShow = false
      this.treeListIds = []
      this.TreeData = []
      this.treeIds = []
    },
    // tree点击
    getCheckedNodes () {
        let res = this.$refs.tree.getCheckedKeys()
        let result = this.$refs.tree.getHalfCheckedKeys()
        this.treeIds = res.concat(result)
    },
    storeMenuTreeSubmit() {
      _api.editCompanyMenu({companyId: this.storeMenuCompanyId,menuIds:this.treeIds}).then(res => {
        if(res.code === 1){
          console.log(res);
          this.$message.success("操作成功");
          this.storeMenuTreeShow = false
          this.configHandleCurrentChange()
        }
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .enable-tip{
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure{
      margin-top: 10px;
      color:#333333;
      font-size:16px;
      text-align: center;
    }
  }
  .command{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .menuTreeBox{
    border: 1px solid #0981FF;
    overflow: hidden;
    border-radius: 10px;
  }
}
</style>
